<template>
  <div id="news">
    <div class="headerBox">
      <Header now-nav="/news" @changeTab="changeTab"></Header>
      <div class="mainContent">
        <p data-aos="fade-down">最新的前沿资讯，了解行业全貌</p>
        <h4 data-aos="fade-down">新闻动态</h4>
        <img :src="baseUrl+'imges/news/newsBG.png'" alt="" ref="cur" class="img_big">
        <img :src="baseUrl+'imges/news/MobileNewsBG.png'" ref="cur1" alt="" class="MobileBG">
      </div>
    </div>

    <!--  内容区  -->
    <div class="contentBox">
      <div class="navHeadPC">
        <div class="leftNews">
          <p @mouseenter="mouseEnter('tab1')" :class="tab1?'isActive':''">公司动态</p>
          <p @mouseenter="mouseEnter('tab2')" :class="tab2?'isActive':''">行业资讯</p>
        </div>
      </div>
      <div class="navHeadMobile" :style="status==='fixed'?'position: fixed;top: 64px;left: 0;z-index:1000;':''">
        <div class="leftNews">
          <p @mouseenter="mouseEnter('tab1')" :class="tab1?'isActive':''">公司动态</p>
          <p @mouseenter="mouseEnter('tab2')" :class="tab2?'isActive':''">行业资讯</p>
        </div>
      </div>
      <div class="newsBox">
        <div class="rightNews">
          <!--    公司动态新闻        -->
          <div v-if="tab1" data-aos="fade-up" data-aos-duration="1000">
            <!--    新闻列表        -->
            <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'dynamicAdd02',name:'蓉视通科技受邀参加2023石油化工数字化（储运）罐区高峰论坛'}}">
              <div class="listBox">
                <div class="conBox">
                  <img :src="baseUrl+'imges/news/newsAdd03/newsAdd03_title.jpg'" alt="">
                  <div class="conText">
                    <h4>蓉视通科技受邀参加2023石油化工数字化（储运）罐区高峰论坛</h4>
                    <span class="newsTime">2023-3-24</span>
                    <p>2023年3月15-17日，2023石油化工数字化（储运）罐区高峰论坛暨第五届全国石油化工罐区测量控制与安全管理技术交流大会在青岛盛大举行。论坛以“会诊罐区隐患，推进安全管理，引领...</p>
                    <p class="keyAndMore">
                    </p>
                    <router-link :to="{name: 'Newslist', params: {type:'dynamicAdd03',name:'蓉视通科技受邀参加2023石油化工数字化（储运）罐区高峰论坛'}}">查看更多 →</router-link>
                  </div>
                </div>
              </div>
            </router-link>

            <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'dynamicAdd02',name:'蓉视通科技——“基于三维可视化的公共管廊智慧管理系统”成功入选2022年智慧化工园区适用技术目录'}}">
              <div class="listBox">
                <div class="conBox">
                  <img :src="baseUrl+'imges/news/newsAdd02/newsAdd02_title.png'" alt="">
                  <div class="conText">
                    <h4>蓉视通科技——“基于三维可视化的公共管廊智慧管理系统”成功入选...</h4>
                    <span class="newsTime">2022-11-28</span>
                    <p>2022年10月，中国石油和化学工业联合会化工园区工作委员会公布了“2022年（第二批）智慧化工园区适用技术目录”的名单，蓉视通科技“基于三维可视化的公共管廊智慧管理系统”成功入选！...</p>
                    <p class="keyAndMore">
                    </p>
                    <router-link :to="{name: 'Newslist', params: {type:'dynamicAdd02',name:'蓉视通科技——“基于三维可视化的公共管廊智慧管理系统”成功入选2022年智慧化工园区适用技术目录'}}">查看更多 →</router-link>
                  </div>
                </div>
              </div>
            </router-link>

            <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'dynamicAdd01',name:'工业物联网软硬件制造商——蓉视通科技精彩亮相IOTE国际物联网展'}}">
              <div class="listBox">
                <div class="conBox">
                  <img :src="baseUrl+'imges/news/newsAdd01/img_title.png'" alt="">
                  <div class="conText">
                    <h4>工业物联网软硬件制造商——蓉视通科技精彩亮相IOTE国际物联网展</h4>
                    <span class="newsTime">2022-11-18</span>
                    <p>第十八届IOTE国际物联网展于2022年11月15日至17日在深圳国际会展中心（宝安新馆）17号馆盛大开展。本次展会完整覆盖了物联网上下游产业链，涵盖物联网感知层、网络传输层、运算与平台层以及应用层，汇聚了400+家品牌参...</p>
                    <p class="keyAndMore">
                    </p>
                    <router-link :to="{name: 'Newslist', params: {type:'dynamicAdd01',name:'工业物联网软硬件制造商——蓉视通科技精彩亮相IOTE国际物联网展'}}">查看更多 →</router-link>
                  </div>
                </div>
              </div>
            </router-link>

            <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'news02',name:'蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展'}}">
              <div class="listBox">
                <div class="conBox">
                  <img :src="baseUrl+'imges/news/news01.png'" alt="">
                  <div class="conText">
                    <h4>蓉视通科技巡视机器人、工业5G路由器、DTUIOTE国际检测物联网展</h4>
                    <span class="newsTime">2021-04-30</span>
                    <p>第021届国际IOTE展至23日在上海国际展览馆举办。展览上十五展示于2000+年全世博全球家展，针对联网物联网产业链完整的日间物联网进行以及链路层、网络传输层、网络传输层、工业层作为物联...</p>
                    <p class="keyAndMore">
                    </p>
                    <router-link :to="{name: 'Newslist', params: {type:'news02',name:'蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展'}}">查看更多 →</router-link>
                  </div>
                </div>
              </div>
            </router-link>

            <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'news03',name:'蓉视通科技荣获成都2020年度5G创新应用优秀项目奖'}}">
              <div class="listBox">
                <div class="conBox">
                  <img :src="baseUrl+'imges/news/news02.png'" alt="">
                  <div class="conText">
                    <h4>蓉视通科技荣获成都2020年度5G创新应用优秀项目奖</h4>
                    <span class="newsTime">2021-03-23</span>
                    <p> 2021年3月18日下午，“2020年度5G产业交流活动”在菁蓉汇隆重举办。成都信息本交流活动是在成都市经济和信息化发展局、高新区新经济局指导下，由成都信息本交流活动产业促进会（成都5G...</p>
                    <p class="keyAndMore">
                    </p>
                    <router-link :to="{name: 'Newslist', params: {type:'news03',name:'蓉视通科技荣获成都2020年度5G创新应用优秀项目奖'}}">查看更多 →</router-link>
                  </div>
                </div>
              </div>
            </router-link>

            <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'news01',name:'公共管廊新国标:园区物料“动脉”数字化建设将提速'}}">
              <div class="listBox">
                <div class="conBox">
                  <img :src="baseUrl+'imges/news/news03.png'" alt="">
                  <div class="conText" style="border: none">
                    <h4>公共管廊新国标：园区物流“动脉”数字化将提速</h4>
                    <span class="newsTime">2019-04-11</span>
                    <p>“2021微信公开课PRO”在广州正式开课，正值微信上线十周年，微信生态持续发展，包括小程序在内的核心功能为用户和企业创造了更多价值。</p>
                    <p class="keyAndMore">
                    </p>
                    <router-link :to="{name: 'Newslist', params: {type:'news01',name:'公共管廊新国标:园区物料“动脉”数字化建设将提速'}}">查看更多 →</router-link>

                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="knowMore">

          </div>
          <!--    行业资讯新闻        -->
          <div v-if="tab2">
            <div v-if="pageOne" data-aos="fade-up" data-aos-duration="1000">
              <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'newsAdd01',name:'地下综合管廊建设提速'}}">
                <div class="listBox">
                  <div class="conBox">
                    <img :src="baseUrl+'imges/news/news-new01.jpg'" alt="">
                    <div class="conText">
                      <h4>地下综合管廊建设提速</h4>
                      <span class="newsTime">2022-10-13</span>
                      <p>今年上半年，全国在建综合管廊项目68个，规划建设规模285公里，预算总投资额294亿元，累计形成廊体33公里，完成投资26亿元。</p>
                      <p class="keyAndMore">

                      </p>
                      <router-link :to="{name: 'Newslist', params: {type:'newsAdd01',name:'地下综合管廊建设提速'}}">查看更多 →</router-link>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'news03',name:'“工业互联网+安全生产”行动计划（2021-2023年）'}}">
                <div class="listBox">
                  <div class="conBox">
                    <img :src="baseUrl+'imges/news/news04.png'" alt="">
                    <div class="conText">
                      <h4>“工业互联网+安全生产”行动计划（2021-2023年）</h4>
                      <span class="newsTime">2021-02-20</span>
                      <p>工业和信息化部 应急管理部关于印发《“工业互联网+安全生产”行动计划（2021-2023年）》的通知</p>
                      <p class="keyAndMore">

                      </p>
                      <router-link :to="{name: 'Newslist', params: {type:'news03',name:'“工业互联网+安全生产”行动计划（2021-2023年）'}}">查看更多 →</router-link>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'news02',name:'智慧医疗和医疗的未来'}}">
                <div class="listBox">
                  <div class="conBox">
                    <img :src="baseUrl+'imges/news/news05.png'" alt="">
                    <div class="conText">
                      <h4>智慧医疗和医疗的未来</h4>
                      <span class="newsTime">2021-02-18</span>
                      <p>目前中国人工智能发展已经进入了一个新阶段，也许现在正是我们赶超世界水平的一个弯道超车的关键节点。人工智能不仅是当下国际竞争的新焦点，也可能成为我国未来经济发展的一个新引擎。</p>
                      <p class="keyAndMore">
                      </p>
                      <router-link :to="{name: 'Newslist', params: {type:'news02',name:'智慧医疗和医疗的未来'}}">查看更多 →</router-link>
                    </div>
                  </div>
                </div>
              </router-link>

              <router-link data-aos="fade-up" data-aos-duration="2000" :to="{name: 'Newslist', params: {type:'news01',name:'打造一体高效综合枢纽 陕西推进智慧绿色交通发展'}}">
                <div class="listBox">
                  <div class="conBox">
                    <img :src="baseUrl+'imges/news/news06.jpg'" alt="">
                    <div class="conText" style="border: none">
                      <h4>打造一体高效综合枢纽 陕西推进智慧绿色交通发展</h4>
                      <span class="newsTime">2021-01-19</span>
                      <p>实施创新驱动，推进北斗、5G、区块链、大数据等技术在交通运输行业应用;强化交通新型基础设施建设，大力发展智慧交通。</p>
                      <router-link :to="{name: 'Newslist', params: {type:'news01',name:'打造一体高效综合枢纽 陕西推进智慧绿色交通发展'}}">查看更多 →</router-link>

                    </div>
                  </div>
                </div>
              </router-link>

            </div>
          </div>
        </div>

        <!--    侧边栏        -->
        <div class="sidebar" data-aos="fade-left" data-aos-duration="1000">
          <p class="sidebar_title">最热资讯</p>
          <div class="sidebar_Box">
            <div class="sidebar_content" @click="$router.push({name: 'Newslist', params: {type:'news02',name:'蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展'}})">
              <img :src="baseUrl+'imges/news/sidebarImg1.png'" alt="">
              <p>蓉视通科技巡视机器人、工业5G路由器、DTUIOTE国际检测物联网展</p>
              <span>时间：2021年04月30日</span>
            </div>
            <div class="sidebar_content" @click="$router.push({name: 'Newslist', params: {type:'news03',name:'蓉视通科技荣获成都2020年度5G创新应用优秀项目奖'}})">
              <img :src="baseUrl+'imges/news/sidebarImg2.png'" alt="">
              <p>蓉视通科技荣获成都2020年度5G创新应用优秀项目奖</p>
              <span>时间：2021年03月23日</span>
            </div>
            <div class="sidebar_content" @click="$router.push({name: 'Newslist', params: {type:'news03',name:'“工业互联网+安全生产”行动计划（2021-2023年）'}})">
              <img :src="baseUrl+'imges/news/sidebarImg3.png'" alt="">
              <p>“工业互联网+安全生产”行动计划（2021-2023年）</p>
              <span>时间：2021年02月20日</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "News",
  data() {
    return {
      tab1: true,
      tab2: false,
      pageOne: true,
      scroll: 0,
      navHeadTop: 0,
      status: '',
    }
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    setTimeout(() =>{
      this.$refs.cur.classList.add('small')
      this.$refs.cur1.classList.add('small')
    }, 400)
    window.addEventListener("scroll", this.handleScroll, true);
    this.navHeadTop = document.getElementsByClassName('navHeadMobile')[0].offsetTop
  },
  methods: {
    //切换tab
    changeTab(e){
      if(e){
        this.tab1 = true
        this.tab2 =false
      } else {
        this.tab1 = false
        this.tab2 = true
      }
    },
    //鼠标事件
    mouseEnter(e) {
      if (e === "tab1") {
        this.tab1 = true;
        this.tab2 = false;

      } else if (e === "tab2") {
        this.tab1 = false;
        this.tab2 = true;
      }
    },
    //分页函数
    pageUpdate() {
      this.pageOne = !this.pageOne;
    },
    handleScroll(){
      this.scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      if(this.scrollTop+30 >= this.navHeadTop){
        this.status = 'fixed'
      } else {
        this.status = ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
#news {
  background: #ECECEC;
  width: 100%;
  min-width: 1250px;
  .headerBox{
    position: relative;
    width: 100%;
    .mainContent{
      cursor: default;
      user-select: none;
      overflow: hidden;
      .img_big{
        display: block;
        width: 100%;
        height: 580px;
        transition: all 1.4s;
        transform: scale(1.06);
      }
      .MobileBG{
        display: none;
      }
      .small{
        transform: scale(1);
      }
      h4{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 241px;
        width: 708px;
        height: 43px;
        font-family: Medium;
        font-size: 50px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: #FFFFFF;
        z-index: 10;
      }
      p{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 868px;
        height: 42px;
        top: 309px;
        font-family: Regular;
        font-size: 20px;
        line-height: 17px;
        letter-spacing: 0em;
        color: #F2F3FE;
        z-index: 10;
      }
      .contentBox{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 528px;
        width: 501px;
        height: 53px;
        background: rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.4);
        font-family: Regular;
        font-size: 14px;
        line-height: 53px;
        letter-spacing: 0em;
        color: #FFFFFF;
        z-index: 10;
      }
    }
  }
  .contentBox {
    background: #FFFFFF;
    .navHeadMobile{
      display: none;
    }
    .navHeadPC {
      display: block;
      width: 100%;
      height: 66px;
      background: #F6F6F6;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      .leftNews {
        position: relative;
        display: flex;
        justify-content: left;
        margin-left: 8vw;
         p{
          height: 62px;
          font-size: 16px;
          margin-right: 70px;
          text-align: left;
          line-height: 66px;
          cursor: pointer;
          font-family: Medium;
          font-weight: 500;
          color: #999999;
        }
        .isActive {
          border-bottom: 4px solid #0564AF;
          color: #0564AF;
          font-family: Medium;
          font-size: 16px;
          font-weight: 500;
          color: #0564AF;
        }
      }
    }
    .newsBox {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 1593px;
      //height: 1100px;
      height: 100%;
      margin: 51px 8vw 0;
      .rightNews {
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 1068px;
        height: 100%;
        background: #FFFFFF;
        margin-bottom: 120px;
        a {
          text-decoration: none;
        }
        //分页
        .pageSite {
          div{
            cursor: pointer;
            width: 120px;
            height: 39px;
            background: #FFFFFF;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            border: 1px solid #999999;
            font-size: 14px;
            font-family: Regular;
            font-weight: 400;
            color: #999999;
            margin: 0 auto;
            line-height: 39px;
          }
          .upButton{
            position: absolute;
            left: 50%;
            transform: translateX(-60%);
            bottom: 94px;
            cursor: not-allowed;
          }
          .downButton{
            position: absolute;
            left: 50%;
            transform: translateX(60%);
            bottom: 94px;
            cursor: not-allowed;

          }
        }
        .listBox {
          position: relative;
          width: 1200px;
          height: 250px;
          cursor: pointer;
          &:hover{
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
          }
          .conBox{
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 24px;
            left: 30px;
            width: 1122px;
            height: 202px;
            img{
              width: 329px;
              height: 202px;
            }
            .conText{
              width: 749px;
              height: 169px;
              margin-top: 16.5px;
              text-align: left;
              h4{
                font-family: Medium;
                font-size: 20px;
                font-weight: 500;
                line-height: 17px;
                margin-bottom: 18px;
                color: #454545;
              }
              .newsTime{
                display: block;
                font-family: Regular;
                font-size: 14px;
                letter-spacing: 0.05em;
                margin-bottom: 18px;
                color: #454545;
              }
              p{
                width: 749px;
                font-family: Regular;
                font-size: 14px;
                line-height: 26px;
                margin-bottom: 18px;
                color: #999999;
              }
              a{
                display: block;
                font-family: Regular;
                font-size: 14px;
                line-height: 26px;
                color: #454545;
              }
            }

          }

          &:hover{
            .conBox{
              transition: all 0.25s linear;
              h4{
                color: #0564AF;
              }
            }
          }
        }

        .noborder {
          border: none;
        }
      }
      .sidebar{
        display: block;
        width: 390px;
        height: 953px;
        .sidebar_title{
          text-align: left;
          margin-left: 27px;
          font-family: Regular;
          font-size: 24px;
          color: #454545;
        }
        .sidebar_Box{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 336px;
          height: 862px;
          margin: 20px auto;
          .sidebar_content{
            width: 336px;
            height: 274px;
            overflow: hidden;
            text-align: left;
            cursor: pointer;
            &:hover{
              p{
                color: #0564AF;
              }
            }
            img{
              width: 100%;
              height: 177px;
              transition: all .6s;
            }
            img:hover{
              transform: scale(1.1);
            }
            p{
              width: 310px;
              margin-top: 10px;
              font-family: Regular;
              font-size: 16px;
              color: #454545;
            }
            span{
              display: block;
              margin-top: 8px;
              height: 20px;
              font-family: Regular;
              font-size: 12px;
              line-height: 20px;
              color: #999999;
            }
          }
        }
      }
    }
  }
}

//媒体查询适应手机端
@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
  }
  #news{
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    .headerBox{
      position: relative;
      margin-top: 50px;
      width: 100%;
      height: 100%;
      .mainContent{
        height: 100%;
        overflow: hidden;
        .img_big{
          display: none;
        }
        .MobileBG{
          width: 100%;
          height: 187px;
          display: block;
          transition: all 1.4s;
          transform: scale(1.06);
        }
        .small{
          transform: scale(1);
        }
        h4{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 72px;
          width: 100%;
          text-align: center;
          height: 13.86px;
          font-family: Medium;
          font-size: 30px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: #FFFFFF;
        }
        p{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 107px;
          width: 100%;
          text-align: center;
          height: 14px;
          font-family: Regular;
          font-size: 12px;
          font-weight: normal;
          line-height: 17px;
          letter-spacing: 0em;
          color: #F2F3FE;
        }
      }
    }
    .contentBox{
      background: #FFFFFF;
      height: 100%;
      .navHeadPC{
        display: none;
      }
      .navHeadMobile{
        display: block;
        width: 100%;
        height: 50px;
        background: #F6F6F6;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .leftNews{
          position: relative;
          display: flex;
          justify-content: left;
          margin-left: 6vw;
          p{
            height: 48px;
            margin-right: 21px;
            text-align: left;
            line-height: 50px;
            cursor: pointer;
            color: #999999;
            font-family: Regular;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.05em;
          }
          .isActive {
            border-bottom: 2px solid #0564AF;
            color: #0564AF;
            font-family: Medium;
            font-size: 12px;
            font-weight: normal;
            color: #0564AF;
          }
        }
      }
      .newsBox{
        position: relative;
        width: 92%;
        height: 100%;
        margin: 31px auto 55px;
        .sidebar{
          display: none;
        }
        .rightNews{
          display: flex;
          flex-direction: column;
          justify-content: left;
          width: 100%;
          height: 100%;
          a {
            text-decoration: none;
            height: 100%;
          }
          .listBox{
            position: relative;
            width: 100%;
            height: 328px;
            margin-bottom: 22px;
            &:hover{
              box-sizing: border-box;
              padding: 8px;
              box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
            }
            .conBox{
              position: static;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: left;
              //border-bottom: 1px solid #D8D8D8;
              img{
                width: 100%;
                height: 170px;
              }
              .conText{
                width: 100%;
                height: 158px;
                margin-top: 18px;
                text-align: left;
                h4{
                  width: 100%;
                  line-height: 20px;
                  font-family: Regular;
                  font-size: 16px;
                  font-weight: normal;
                  letter-spacing: 0em;
                  color: #454545;
                }
                .newsTime{
                  display: block;
                  font-family: Regular;
                  width: 100%;
                  height: 14px;
                  margin: 10px 0;
                  font-size: 10px;
                  font-weight: normal;
                  letter-spacing: 0.05em;
                  color: #454545;
                }
                p{
                  font-family: Regular;
                  width: 100%;
                  height: 40px;
                  font-size: 12px;
                  font-weight: normal;
                  line-height: 20px;
                  letter-spacing: 0em;
                  color: #999999;

                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box; //将对象作为弹性伸缩盒子模型显示 *必须结合的属性*
                  -webkit-box-orient: vertical; //设置伸缩盒对象的子元素的排列方式 *必须结合的属性*
                  -webkit-line-clamp: 2; //用来限制在一个块元素中显示的文本的行数
                  word-break: break-all; //让浏览器实现在任意位置的换行 *break-all为允许在单词内换行*
                }
                a{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
